import { MenuDataItem } from '@ant-design/pro-layout'
import { Route } from '@ant-design/pro-layout/es/typing'
import { useQuery } from '@apollo/client'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { ProductProviderMemberDocument } from '../../generated/graphql'
import { Footer } from './Footer'
import { PageContainer } from './PageContainer'

const AntdProLayout = dynamic(
  async () => (await import('@ant-design/pro-layout')).ProLayout,
  { ssr: false }
)

const Logo: React.ReactNode = (
  <Image src="/logo.svg" width={32} height={32} alt="logo" />
)

type Props = {
  routes: {
    owner: Route
    my_pass_reviewer: Route
  }
  children: React.ReactNode
}

export const BasicLayout: React.FC<Props> = ({ routes, children }) => {
  const { loading, data } = useQuery(ProductProviderMemberDocument)
  const router = useRouter()
  const handleMenuHeaderClick = useCallback(() => router.push('/'), [router])
  const handleMenuItemRender = useCallback(
    (options: MenuDataItem, element: React.ReactNode) => (
      <Link href={options.path!}>{element}</Link>
    ),
    []
  )

  if (loading || !data) return <PageContainer loading />

  return (
    <AntdProLayout
      title="RYDE PASS"
      logo={Logo}
      route={routes[data?.productProviderMember.role]}
      navTheme="light"
      layout="side"
      fixSiderbar
      onMenuHeaderClick={handleMenuHeaderClick}
      menuItemRender={handleMenuItemRender}
      footerRender={Footer}
      style={layoutStyle}
    >
      {children}
    </AntdProLayout>
  )
}

const layoutStyle = { minHeight: '100vh' }
